import QuoteLeft from 'assets/images/quote-left.svg';

import YesIcon from 'assets/images/yes-icon.svg';
import TickIcon from 'assets/images/tick-icon.svg'

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import FooterLogo from 'assets/images/logo-footer.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Vyne Dental',
  tokenName: 'vyneSubscriberToken',
  globalClass: 'vyne-container',
  clientHeader,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: true,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 1,
      titleWrapperValue: 7,
      imageWrapperValue: 5,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      secondaryButtonProps: {
        scrollTo: 'howitworks-section',
      },
      heroImage: '/images/home-images/home-banner-img.webp',
      verticalAlignImage: 'bottom',
      textWrapperSize: {
        xs: 12,
        md: 7
      },
      imageWrapperSize: {
        xs: 12,
        md: 5
      }
    },
    benefitsSection: {
      variant: 5,
      columns: 2,
    },
    subscriptionSection: {
      cardVariant: 4,
      tabVariant: 2,
      renderAsCardGrid: true,
      frequencyPlans: true,
      offerText1Variant: "body1",
      offerText2Link: '',
      offerText2Action: {
        type: 'popup',
        action: 'savings-summary'
      },
      gridModel: true,
      carouselResponsiveItems: {
        superLargeDesktop: 4,
        desktop: 4,
      },
      plansPerRow: {
        lg: 4,
      },
      showAddons: false,
      planCardProps: {
        cardGroup: true,
      },
      planNameProps: {
        showIcon: true,
        icons: {
          'Essential': '/images/home-images/plan-icon/essentialplan.webp',
          'Advantage': '/images/home-images/plan-icon/advantageplan.webp',
          'Premier': '/images/home-images/plan-icon/premierplan.webp',
          'Discount Plan': '/images/home-images/plan-icon/discountplan.webp',
        },
      },
    },
    findPlanProps: {
      plansPerRow: {
        lg: 4,
      },
    },
    howItWorksSection: {
      variant: 3,
      alignTitle: 'center',
    },
    testimonialSection: {
      variant: 1,
      alignTitle: 'left',
      heroImage: '/images/home-images/testimonial.webp',
      sectionClass: 'section-container',
      messageType: 'h6',
      quoteImage: QuoteLeft,
    },
    ribbonSection: {
      enable: true
    },
    staticHeroSection: {
      enable: false,
      heroImage: '/images/home-images/footer.webp',
    },
  },
  checkoutSection: {},
  findProviderSection: {},
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 4,
    tabVariant: 2,
    renderAsCardGrid: true,
    carouselResponsiveItems: {
      superLargeDesktop: 4,
      desktop: 4,
    },
    plansPerRow: {
      lg: 4,
    },
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly',
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        normalVariant: 'contained',
        highlightedColor: 'primary',
        highlightedVariant: 'contained',
      },
    },
    staticHeroSection: {
      enable: true,
      linkTo: '/faq',
      variant: 2,
      heroImage: '/images/home-images/footer.webp',
    },
  },
  viewPlans: {
    heroSection: {
      titleClasses: 'view-hero-title',
      subTitleClasses: 'view-hero-subtitle',
    },
    testimonialSection: {
      variant: 1,
      alignTitle: 'left',
      heroImage: '/images/home-images/testimonial.webp',
      sectionClass: 'section-container',
      messageType: 'h6',
      quoteImage: QuoteLeft,
    },
    staticHeroSection: {
      enable: true,
      variant: 2,
      heroImage: '/images/home-images/footer.webp',
    }
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: false,
  },
  savingsSummary: {},
  faqSection: {},
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  footer: {
    enable: true,
    logo: FooterLogo,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
