export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#9CC841',
    contrastText: '#FFFFFF',
    light: '#80A8681a',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#668E4D',
  },
  text: {
    primary: '#1B264F',
    secondary: '#64748B',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#F0F3FC66',
    light: '#E8E7E7',
    paper: '#FFF',
    primary: '#EFF2FB',
    default: '#FFF',
    footer: '#0B1E37',
    level2: '#f7f8fb',
    level1: '#FFF',
    bar: '#213053',
    plansHeader: 'rgba(240, 243, 252, 0.6)',
    tableHead: '#1B264F',
    tableSubhead: '#F0F3FC66',
    tableAlternate: '#F8F8F8',
    ribbon: '#1C4992',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    secondary: '#2A5A75',
    testimonial: 'rgba(240, 243, 252, 0.4)',
  },
  verticalBar: {
    used: '#80A868',
    unused: '#80A8681a',
    unlimited: '#C0C0C0',
  }
};
