import { palette } from './palette';

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      maxWidth: 'unset',

      '& .logo-image': {
        height: 56,
      },

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
      },
    },
  },

  /* Savings Summary - Popup and page */
  '& .savings-wrapper': {
    '& .service-plan-table .savings-topheader': {
      color: `${palette.text.highlight} !important`,
    },
    '& .savings-contact-card': {
      backgroundColor: palette.background.main,
    },
    '& .footer-bar': {
      borderRadius: '8px',
      justifyContent: 'center'
    },
    '& .footer-bar-text ': {
      color: palette.text.primary,
    },
  },

  '.vyne-container': {
    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Header */
    '& .header-wrapper': {
      borderBottom: '1px solid rgba(8, 69, 107, 0.10)',

      '& .top-bar-logo-container .image': {
        width: 'auto',
        height: '100%',
      },
    },

    /* Common style */
    '& .savings-summary-link': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      background: `url('./images/home-images/hero-bg.webp') no-repeat center`,
      backgroundSize: 'cover',

      '& .hero-section-alternate': {
        height: 'auto !important',
        paddingBottom: 0,

        '& .hero-title': {
          lineHeight: '55px',

          '@media(min-width: 900px)': {
            lineHeight: '80px',
          },
        },
      },

      '@media(max-width: 900px)': {
        '& .hero-title': {
          marginTop: '35px',
        }
      },

      '& .home-hero-title': {
        maxWidth: '370px',
        marginBottom: '10px',
      },
      
      '& .image-wrapper': {
        '& .image': {
          boxShadow: 'none',
          height: 'auto',
        },
      },
    },

    /* View Hero */
    '& .view-hero-wrapper': {
      background: `url('./images/home-images/hero-bg.webp') no-repeat center`,
      backgroundSize: 'cover',

      '& .view-hero-section-alternate': {
        height: 'auto !important',
        paddingBottom: 0,

        '& .hero-title': {
          lineHeight: '55px',
  
          '@media(max-width: 900px)': {
            marginTop: '50px',
          },
          '@media(min-width: 900px)': {
            lineHeight: '80px',
          },
        },
        '& .hero-subtitle': {
          maxWidth: '550px',
          marginBottom: '30px'
        },
      },
    },

    /* Benefits - Styles */
    '& #definition-section': {
      '& .section-header__title': {
        fontSize: '32px'
      },
      '@media(max-width: 900px)': {
        paddingTop: 0,
      },
      '& .benefits-wrapper': {
        paddingTop: 48,

        '& .section-header': {
          '@media(min-width: 900px)': {
            paddingBottom: 50,
          },
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      backgroundColor: palette.background.main,

      '& .subscription-bg': {
        display: 'none',
      },
      '& #pricing-benefit-section': {
        '& .subscription-section': {
          '& .section-header': {
            maxWidth: 650,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
          '& .section-header__title': {
            fontSize: '32px'
          }
        },

        '& .summary-text': {
          fontWeight: 400,
        },

        '& .carousel-container': {
          '& .react-multi-carousel-dot--active button': {
            backgroundColor: palette.text.primary,
          },
        },

        '& .planCard': {
          color: palette.text.primary,
          borderWidth: 2,

          '& .MuiButton-root': {
            borderWidth: 2,
            color: palette.text.primary,
          },
        },

        '& .planCard:hover': {
          '& .MuiButton-root': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
      },
    },

    '& .findPlans-wrapper': {
      background: `url('./images/home-images/hero-bg.webp') no-repeat center`,
      backgroundColor: palette.background.plansHeader,
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: 'unset',

      '& .howitworks-wrapper': {
        '& .how-cover-image': {
          height: 'auto',
        },
        '& .section-header__title': {
          fontSize: '32px',
        },
      },
      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
        marginBottom: 0,
        borderBottom: `1px solid ${palette.text.disabled}`,
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      '@media(min-width: 900px)': {
        padding: 72,
      },

      '& .section-alternate': {
        background: `url('./images/home-images/hero-bg.webp') no-repeat center`,
        backgroundColor: palette.background.testimonial,
        backgroundSize: 'cover',
      },
      '& .testimonialSection': {
        padding: '40px 32px',

        '@media(min-width: 900px)': {
          padding: '40px 16px',
        },
        '& .testimonial-container': {
          justifyContent: 'center',

          '& .testimonial-content-wrapper': {
            padding: 32,
            height: '290px',
            lineHeight: '80px',

            '& .react-multi-carousel-dot--active button': {
              backgroundColor: palette.text.primary,
            },
            '& .react-multi-carousel-dot-list': {
              justifyContent: 'left',
            },

            '@media(max-width: 900px)': {
              lineHeight: '20px',
            },
          },

          '& .image-wrapper': {
            '& .image': {
              height: '425px'
            }
          },

          '& .testimonial-message': {
            color: palette.text.primary,
            fontSize: 32,
            lineHeight: 1.25,
            fontWeight: 500,
          },
          '& .testimonial-name, .react-multi-carousel-dot-list': {
            display: 'none'
          },

          '& .react-multi-carousel-track': {
            transition: 'none !important',
            animationDuration: '0s !important',
            transform: 'translate3d(0px, 0px, 0px) !important'
          },
        },
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.background.bar,
      },
      '& .hero-static-section': {
        '& .section-header__title, & .section-header__subtitle': {
          color: palette.text.highlight,
          maxWidth: 600,
        },
      },
    },
    '& .staticHero2-content-wrapper': {
        '& .section-header__subtitle': {
          maxWidth: '550px'
        },
    },

    /* Static Page Hero Banners */
    '& .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section, & .savings-hero-section, & .faq-hero-section, & .faq-contact-wrapper, & .find-provider-section, & .checkout-hero-section': {
      backgroundColor: palette.background.main,
      paddingTop: 64,
    },
    '& .book-appointment-hero-section': {
      height: '100%',
      backgroundColor: palette.background.main,

      '& .MuiTypography-root': {
        color: palette.text.primary,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .planCard': {
        '&:hover': {
          color: palette.text.primary,
        },
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .provider-details': {
        border: `1px solid ${palette.primary.main}`,
        '& .customEditIcon, .customEditButton': {
          stroke: palette.background.secondary,
          color: palette.background.secondary,
        },
      },
      '& .plans-wrapper': {
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled,
        },
      },
      '& .plan-selection-section': {
        background: `url('./images/home-images/choose-plan.webp') no-repeat`,
        backgroundSize: 'cover',
        marginTop: '50px',
        '& .section': {
          display: 'flex',
          justifyContent: 'start',
          marginLeft: '50px',
        },
        '& .section-header__title-wrapper': {
          paddingBottom: '15px',
        },
        '& .hero-header-title, .hero-header-subtitle ': {
          color: palette.text.highlight,
          textAlign: 'left',
          margin: 0,
        },
        '& .hero-header-subtitle': {
          maxWidth: '520px'
        },
        '& .section-header': {
          maxWidth: '600px',
          padding: '10px',
        },
        '@media(max-width: 900px)': {
          '& .section-header': {
            maxWidth: '100%',
            padding: 0
          },
          '& .section': {
            marginLeft: '0px'
          }
        },
      },
      '& .staticHero2-content-text-wrapper': {
        '& .section-header__title-wrapper': {
          '& .section-header__title': {
            maxWidth: '580px'
          }
        }
      }
    },

    /* Find Provider */
    '& .checkout-flow-container': {
      '& .provider-home-button': {
        color: palette.text.primary,
        border: `2px solid ${palette.primary.main}`,
      },
      '& .provider-home-button:hover': {
        border: `2px solid ${palette.primary.main}`,
      },
    },

    /* FAQ */
    '& .faqInnerContainer': {
      '& .accordion__collapsable-text, & .section-header__subtitle': {
        color: palette.text.secondary,
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
    },

    '& .footer-wrapper .logo-image': {
      height: 40,
    },

    '& .service-plan-table': {
      border: 'none'
    },

    '& .MuiTable-root': {
      color: 'white',
      border: 'none',
    },
    '& .MuiTableRow-root': {
      '&:nth-child(odd)': {
        backgroundColor: palette.background.tableAlternate,
      },
      '&:nth-child(even)': {
        backgroundColor: palette.background.paper,
      },
      
      '& .MuiTableCell-root': {
        fontSize: 16,
        whiteSpace: 'nowrap',
        color: palette.text.secondary,
        border: `0.1px solid ${palette.background.tableBorder}`,
  
        '&.savings-topheader': {
          backgroundColor: palette.background.tableHead,
          color: palette.text.primary,
          fontWeight: 700,
        },
  
        '&.savings-header': {
          backgroundColor: palette.background.tableSubhead,
          color: palette.text.contrastText,
          fontWeight: 600,
        },
      },
      '& .MuiTableCell-head': {
        color: palette.text.highlight
      }
  
    },
    '& .success-wrapper': {
      maxWidth: '485px'
    }
  },

  /* footer */
  '& .footer_link': {
    textDecoration: 'underline'
  }
};